import {CompositeFilterDescriptor, SortDescriptor, State} from '@progress/kendo-data-query';

export interface ColumnSetting {
  field: string;
  textField?: string;
  title: string;
  filterable?: boolean;
  filter?: string;
  locked?: boolean;
  width?: number;
  minWidth?: number;
  visible?: boolean;
}

export interface IGridFilterDescriptor {
  operator: string;
  value: any;
}

export interface IGridSortDescriptor {
  dir: 'asc' | 'desc';
}

export interface IGridColumnDefaults {
  filter?: IGridFilterDescriptor;
  sort?: IGridSortDescriptor;
  width?: number;
  minWidth?: number;
}

export interface GridColumn {
  name: string;
  field: string;
  isDefault?: boolean;
  ordinal?: number;
  width?: number;
  minWidth?: number;
  resizeable?: boolean;
  defaults?: IGridColumnDefaults;
}

export interface IGridColumnState {
  visible: boolean;
  width: number;
  minWidth: number;
}

export class GridColumnState {
  showEditColumns?: boolean = false;
  state: {
    [propName: string]: IGridColumnState;
  } = {};
  options: GridColumn[] = [];
  selected: GridColumn[] = [];

  constructor(options: GridColumn[] = [], preselected: GridColumn[] = []) {
    this.options = options;
    this.selected = preselected;
  }

  toggleShowColumns() {
    this.showEditColumns = !this.showEditColumns;
  }
}

export interface KendoGridConfig {
  /** Initial grid column configuration */
  columns: KendoGridColumn[];
  defaultSort?: SortDescriptor[];
  defaultFilter?: CompositeFilterDescriptor;
}

export interface KendoGridColumn {
  columnMenu?: boolean;
  editable?: boolean;
  editor?: string;
  field?: string;
  filter?: string;
  filterable?: boolean;
  format?: string;
  groupable?: boolean;
  hidden?: boolean;
  isColumnGroup?: boolean;
  isSpanColumn?: boolean;
  lockable?: boolean;
  locked?: boolean;
  matchesMedia?: boolean;
  minResizableWidth?: number;
  orderIndex?: number;
  parent?: KendoGridColumn;
  reorderable?: boolean;
  resizable?: boolean;
  sortable?: boolean;
  title?: string;
  _width?: number;
  // Not in Kendo API
  defaultSort?: { dir: 'asc' | 'desc' };
  defaultFilter?: Array<{ operator: string, value: boolean }>;
}

export interface KendoGridSettings {
  state: State;
  columnsConfig: any[];
}

export interface KendoGridDataConfig {
  /** The full API URL to call to get grid data */
  url: string;
  /** The name of the grid. Used for storing and retrieving cached grid state */
  name: string;
  /** Whether the grid should cache the current state when the data is updated */
  cache?: boolean;
}

export interface UpdateGridOptions {
  /** Stops the grid data from updating */
  preventDataUpdate?: boolean;
}

export interface ResetGridOptions {
  /** Whether to reset the grid state */
  state?: boolean;
  /** Whether to reset the filters that are outside of the grid*/
  otherFilters?: boolean;
  /** Whether to reset the grid columns */
  columns?: boolean;
  /** Whether to update the grid data after resetting the configuration */
  update?: boolean;
}


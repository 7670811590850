<pt-sidebar *ngIf="!!user">
  <ng-container>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/alerts">
        <i class="fas fa-bell" title="Alerts"></i>
        <span class="text">Alerts <span *ngIf="!!(counts?.alerts)">({{counts?.alerts}})</span></span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/customers">
        <i class="fas fa-users" title="Customers"></i>
        <span class="text">Customers</span>
      </a>
    </li>
  </ng-container>
</pt-sidebar>

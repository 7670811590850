import {Component, OnInit} from '@angular/core';
import {User} from '../../../security/models/security.models';
import {AuthService} from '../../../security/services/auth/auth.service';
import {Counts} from '../../models/counts.model';
import {CountService} from '../../services/count.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  user: User;
  counts: Counts;

  constructor(
    private authService: AuthService,
    private countService: CountService
  ) {}

  ngOnInit() {
    this.authService.getUser().subscribe(user => {
      this.user = user;
    });

    this.countService.getCounts().subscribe(counts => {
      this.counts = counts;
    });

    this.countService.change.subscribe(() => {
      this.countService.getCounts().subscribe(counts => {
        this.counts = counts;
      });
    });
  }
}

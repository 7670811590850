import {Component, Inject, OnInit} from '@angular/core';
import { User } from '../../../security/models/security.models';
import { AuthService } from '../../../security/services/auth/auth.service';
import { APP_CONFIG, IAppConfig } from '../../models/configuration.models';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  user: User;
  imgSrc: string;
  imgAlt: string;
  title: string;
  links: any[] = [
    { href: '/plans', 'text': 'Plans' },
    { href: '/security/users', 'text': 'Users' }
  ];

  constructor(
    @Inject(APP_CONFIG) private config: IAppConfig,
    private authService: AuthService
  ) {
    this.title = config.appName;
    this.imgSrc = config.logoUrl;
    this.imgAlt = config.logoAlt;
  }

  ngOnInit() {
    this.authService.getUser().subscribe(user => {
      this.user = user;
    });
  }
}

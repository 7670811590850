import { Component, OnInit, Input, Inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'pt-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @Input('title') title: string;
  @Input('imgSrc') imgSrc: string;
  @Input('imgAlt') imgAlt: string;

  environmentLabel: string = environment.name;
  open: boolean = false;
  loggedIn: boolean = false;

  constructor(
  ) {

  }

  ngOnInit() {
  }
}

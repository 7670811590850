import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { APP_CONFIG, IAppConfig } from '../../../_shared/models/configuration.models';

@Injectable()
export class PermissionService {
  private permissions: any;
  private permissionsTask: Observable<boolean>;

  constructor(
    @Inject(APP_CONFIG) private config: IAppConfig,
    private http: HttpClient
  ) { }

  getPermissions<T>(): T {
    return this.permissions;
  }

  loadPermissions<T>(): Observable<boolean> {
    if (this.permissionsTask)
      return this.permissionsTask;

    return this.permissionsTask = this.http
      .get<T>(`${this.config.apiUrl}/security/permissions`)
      .mergeMap((permissions => {
        this.permissions = permissions;
        return Observable.of(true);
      }));
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@progress/kendo-angular-buttons/dist/es2015/index.ngfactory";
import * as i3 from "@progress/kendo-angular-buttons";
import * as i4 from "@progress/kendo-angular-l10n";
import * as i5 from "@progress/kendo-angular-popup";
import * as i6 from "../../../_foundation/components/layout/nav/nav.component.ngfactory";
import * as i7 from "../../../_foundation/components/layout/nav/nav.component";
import * as i8 from "@angular/common";
import * as i9 from "./navbar.component";
import * as i10 from "../../models/configuration.models";
import * as i11 from "../../../security/services/auth/auth.service";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
function View_NavbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.href; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.text; _ck(_v, 1, 0, currVal_1); }); }
function View_NavbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "kendo-dropdownbutton", [], [[2, "k-state-focused", null], [2, "k-widget", null], [2, "k-dropdown-button", null], [1, "dir", 0]], [[null, "keydown"], [null, "keypress"], [null, "keyup"], [null, "mousedown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).keydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).keypress($event) !== false);
        ad = (pd_1 && ad);
    } if (("keyup" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).keyup($event) !== false);
        ad = (pd_2 && ad);
    } if (("mousedown" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).mousedown($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_DropDownButton_0, i2.RenderType_DropDownButton)), i1.ɵprd(512, null, i3.ɵc, i3.ɵc, []), i1.ɵprd(256, null, i3.ɵf, { useLeftRightArrows: true }, []), i1.ɵprd(512, null, i3.ɵd, i3.ɵd, [i3.ɵf]), i1.ɵprd(256, null, i4.L10N_PREFIX, "kendo.dropdownbutton", []), i1.ɵprd(131584, null, i4.LocalizationService, i4.LocalizationService, [i4.L10N_PREFIX, [2, i4.MessageService], [2, i4.RTL]]), i1.ɵdid(6, 180224, null, 1, i3.DropDownButton, [i3.ɵc, i3.ɵd, i1.ElementRef, i1.NgZone, i5.PopupService, i4.LocalizationService], { iconClass: [0, "iconClass"], popupSettings: [1, "popupSettings"], data: [2, "data"] }, null), i1.ɵqud(335544320, 1, { itemTemplate: 0 }), i1.ɵpod(8, { animate: 0 }), (_l()(), i1.ɵand(0, null, 0, 1, null, View_NavbarComponent_2)), i1.ɵdid(10, 16384, [[1, 4]], 0, i3.ɵg, [i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "fa fa-cog"; var currVal_5 = _ck(_v, 8, 0, true); var currVal_6 = _co.links; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).focused; var currVal_1 = i1.ɵnov(_v, 6).widgetClasses; var currVal_2 = i1.ɵnov(_v, 6).widgetClasses; var currVal_3 = i1.ɵnov(_v, 6).dir; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "pt-nav", [], null, null, null, i6.View_NavComponent_0, i6.RenderType_NavComponent)), i1.ɵdid(1, 114688, null, 0, i7.NavComponent, [], { title: [0, "title"], imgSrc: [1, "imgSrc"], imgAlt: [2, "imgAlt"] }, null), (_l()(), i1.ɵeld(2, 0, null, 1, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_1)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.imgSrc; var currVal_2 = _co.imgAlt; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (!!_co.user && (_co.user.role.id == "administrator")); _ck(_v, 4, 0, currVal_3); }, null); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i9.NavbarComponent, [i10.APP_CONFIG, i11.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarComponentNgFactory = i1.ɵccf("app-navbar", i9.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };

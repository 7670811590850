import { Injectable } from '@angular/core';

@Injectable()
export class DateParseService {
  resolveDates(value: any): any {
    if (typeof value === 'string') {
      return this.resolveDate(value);
    } else if (Array.isArray(value)) {
      value.forEach(e => {
        this.resolveDates(e);
      });
    } else if (value instanceof Object) {
      for (const property in value) {
        if (value.hasOwnProperty(property)) {
          value[property] = this.resolveDates(value[property]);
        }
      }
    }

    return value;
  }

  resolveDate(value: string): any {
    if (value === '0001-01-01T00:00:00Z') {
      return new Date();
    }

    if (this.isDate(value)) {
      return new Date(value);
    }

    return value;
  }

  isDate(value: string): boolean {
    const dateFormat = /^(\d{4}|\+\d{6})(?:-(\d{2})(?:-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})\.?(\d*)(Z|([\-+])(\d{2}):(\d{2}))?)?)?)$/;
    return dateFormat.test(value);
  }
}

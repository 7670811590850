import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { DropDownButtonModule } from '@progress/kendo-angular-buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/takeWhile';
import { FoundationModule } from './_foundation/foundation.module';
import { APP_CONFIG } from './_shared/models/configuration.models';
import { SharedModule } from './_shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { AuthGuardService } from './security/services/auth-guard/auth-guard.service';
import { AuthInterceptor } from './security/services/auth-interceptor/auth.interceptor';
import { AuthService } from './security/services/auth/auth.service';

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'app-Coop.FuneralBonds' }),
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    LoadingBarHttpClientModule,
    ModalModule.forRoot(),
    AppRoutingModule,
    FoundationModule,
    SharedModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: APP_CONFIG, useClass: AppConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthService,
    AuthGuardService
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

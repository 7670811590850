import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    // private notificationService: NotificationsService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({ withCredentials: true });

    return next.handle(req).do(
      () => {
      },
      event => {
        if (event instanceof HttpErrorResponse) {
          if (event.status === 401 || event.status === 403) {
            this.router.navigate(
              ['error', { title: event.statusText, message: 'Restricted content' }],
              { skipLocationChange: true }
            ).then();
          } else if (event.status === 412) {
            // const notification = this.notificationService.error(
            //   'Validation error',
            //   'Click to view',
            //   { timeOut: 5000 }
            // );
            // notification.click.subscribe(() => window.scrollToTop());
          } else if (event.status === 500) {
            // this.notificationService.warn(
            //   `Something went wrong`,
            //   'Contact a system administrator for assistance',
            //   { timeOut: 10000 }
            // );
          }
        }
      });
  }
}

import { IAppConfig } from './_shared/models/configuration.models';
import { environment } from '../environments/environment';

export class AppConfig implements IAppConfig {
  appName = 'EOE Funeral Bonds';
  version = '0.0.1';
  apiUrl = environment.apiUrl;
  logoUrl = 'assets/images/logo.png';
  logoAlt = 'East of England Co-op Funeral Services';
  errorRoute = '/error';
  adminContact = environment.adminContact;
  auth = {
    defaultRoute: '/customers'
  };
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './security/services/auth-guard/auth-guard.service';

const routes: Routes = [
  {
    path: 'alerts',
    loadChildren: 'app/alerts/alerts.module#AlertsModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'customers',
    loadChildren: 'app/policies/policies.module#PoliciesModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'plans',
    loadChildren: 'app/plans/plans.module#PlansModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'security',
    loadChildren: 'app/security/security.module#SecurityModule',
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: '/customers', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


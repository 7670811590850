<h3>{{title}}</h3>
<hr/>

<div *ngIf="isRestrictedMessage">
    <p>You do not have permission to access this content.<br> Please contact your system administrator via   
      <a [href]="mailTo + '?Subject=Restricted%20Content'" target="_top">{{adminContact}}</a>   
      for assistance.</p>   
</div>

<div *ngIf="!isRestrictedMessage">
    <p>{{message}}</p>      
</div>

import { InjectionToken } from '@angular/core';

export interface IAppConfig {
  appName: string;
  version: string;
  apiUrl: string;
  logoUrl: string;
  logoAlt: string;
  errorRoute: string;
  adminContact: string;
  auth?: {
    defaultRoute: string;
  };
}

export const APP_CONFIG = new InjectionToken<IAppConfig>('APP_CONFIG');

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {APP_CONFIG, IAppConfig} from '../models/configuration.models';
import {Counts} from '../models/counts.model';
import {Subject} from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class CountService {
  readonly baseUrl = `${this.config.apiUrl}/counts`;
  public change: Subject<void> = new Subject();

  constructor(
    @Inject(APP_CONFIG) private config: IAppConfig,
    private http: HttpClient
  ) { }

  getCounts(): Observable<Counts> {
    return this.http.get<Counts>(this.baseUrl);
  }
}

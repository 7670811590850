import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs/Observable';
import { APP_CONFIG, IAppConfig } from '../../../_shared/models/configuration.models';
import { PermissionId, User } from '../../models/security.models';

@Injectable()
export class AuthService {
  private user: User;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: IAppConfig
  ) { }

  getUser(): Observable<User> {
    if (!!this.user)
      return of(this.user);

    return this.http.get<User>(`${this.config.apiUrl}/auth/user`)
      .mergeMap(user => {
        this.user = user;
        return of(user);
      });
  }
}



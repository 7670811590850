<app-navbar></app-navbar>

<div class="layout-wrapper">
  <app-sidebar></app-sidebar>
  <div class="layout-content">
    <main class="container-fluid body-content">
      <ngx-loading-bar [includeSpinner]="false" height="4px" color="#ffdc3b"></ngx-loading-bar>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>

<span class="environment-label" *ngIf="environmentLabel != 'LIVE'">{{environmentLabel.toUpperCase()}}</span>

<div toastContainer></div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sidebar.component";
var styles_SidebarComponent = [i0.styles];
var RenderType_SidebarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
export function View_SidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "nav", [["id", "sidebar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ul", [["class", "nav flex-column"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_SidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pt-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i2.SidebarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i1.ɵccf("pt-sidebar", i2.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, ["*"]);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };

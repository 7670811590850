<pt-nav [title]="title" [imgSrc]="imgSrc" [imgAlt]="imgAlt">
  <ng-container right class="p-5">

    <kendo-dropdownbutton
      *ngIf="!!user && user.role.id == 'administrator'"
      [data]="links"
      [iconClass]="'fa fa-cog'"
      [popupSettings]="{ animate: true }">
      <ng-template kendoDropDownButtonItemTemplate let-dataItem>
        <a [href]="dataItem.href">{{ dataItem.text }}</a>
      </ng-template>
    </kendo-dropdownbutton>
  </ng-container>
</pt-nav>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../_foundation/components/layout/sidebar/sidebar.component.ngfactory";
import * as i3 from "../../../_foundation/components/layout/sidebar/sidebar.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./sidebar.component";
import * as i7 from "../../../security/services/auth/auth.service";
import * as i8 from "../../services/count.service";
var styles_SidebarComponent = [i0.styles];
var RenderType_SidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
function View_SidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.counts == null) ? null : _co.counts.alerts); _ck(_v, 1, 0, currVal_0); }); }
function View_SidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "pt-sidebar", [], null, null, null, i2.View_SidebarComponent_0, i2.RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i3.SidebarComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 20, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "li", [["class", "nav-item"], ["routerLinkActive", "active"]], null, null, null, null, null)), i1.ɵdid(4, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 6, "a", [["class", "nav-link"], ["routerLink", "/alerts"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, [[2, 4]], 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fas fa-bell"], ["title", "Alerts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Alerts "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_2)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 8, "li", [["class", "nav-item"], ["routerLinkActive", "active"]], null, null, null, null, null)), i1.ɵdid(15, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(18, 0, null, null, 4, "a", [["class", "nav-link"], ["routerLink", "/customers"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 671744, [[4, 4]], 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "fas fa-users"], ["title", "Customers"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customers"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "active"; _ck(_v, 4, 0, currVal_0); var currVal_3 = "/alerts"; _ck(_v, 8, 0, currVal_3); var currVal_4 = !!((_co.counts == null) ? null : _co.counts.alerts); _ck(_v, 13, 0, currVal_4); var currVal_5 = "active"; _ck(_v, 15, 0, currVal_5); var currVal_8 = "/customers"; _ck(_v, 19, 0, currVal_8); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 8).target; var currVal_2 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_6 = i1.ɵnov(_v, 19).target; var currVal_7 = i1.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_6, currVal_7); }); }
export function View_SidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.user; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i6.SidebarComponent, [i7.AuthService, i8.CountService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i1.ɵccf("app-sidebar", i6.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };

import { HttpClient } from '@angular/common/http';
import { IAppConfig } from '../models/configuration.models';
import { Subject } from 'rxjs/Subject';
import * as i0 from "@angular/core";
import * as i1 from "../models/configuration.models";
import * as i2 from "@angular/common/http";
var CountService = /** @class */ (function () {
    function CountService(config, http) {
        this.config = config;
        this.http = http;
        this.baseUrl = this.config.apiUrl + "/counts";
        this.change = new Subject();
    }
    CountService.prototype.getCounts = function () {
        return this.http.get(this.baseUrl);
    };
    CountService.ngInjectableDef = i0.defineInjectable({ factory: function CountService_Factory() { return new CountService(i0.inject(i1.APP_CONFIG), i0.inject(i2.HttpClient)); }, token: CountService, providedIn: "root" });
    return CountService;
}());
export { CountService };

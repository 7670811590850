<nav class="navbar navbar-expand-lg navbar-dark">
  <a class="navbar-brand"
     href="/">
    <img [src]="imgSrc" class="img-logo mr-auto pl-4" [alt]="imgAlt">
  </a>

  <button *ngIf="loggedIn" class="navbar-toggler"
          type="button"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="open = !open">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse"
       id="navbarNav"
       [collapse]="!open">
    <ul class="nav navbar-nav mr-auto mt-2 mt-lg-0">
      <ng-content select="[left]"></ng-content>
    </ul>
    <ul class="nav navbar-nav">
      <ng-content select="[right]"></ng-content>
    </ul>
  </div>
</nav>


import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DirtyRecordDirective } from '../directives/dirty-record.directive';

@Injectable()
export class DirtyRecordGuard implements CanDeactivate<IDirtyRecordGuard> {

  constructor(
    private dialogService: DialogService
  ) { }

  canDeactivate(
    component: IDirtyRecordGuard,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (!component.dirty)
      return true;

    let p = confirm('You have unsaved changes. Press OK to continue without saving, or cancel to save your changes');

    return p == true;
  }

}

export interface IDirtyRecordGuard {
  dirty: boolean;
  dirtyRecordDirective: DirtyRecordDirective;
}
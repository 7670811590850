import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ActionSheetComponent } from './components/action-sheet/action-sheet.component';
import { ActionSheetService } from './components/action-sheet/action-sheet.service';
import { ErrorComponent } from './components/error/error.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { TickCrossComponent } from './components/tick-cross/tick-cross.component';
import { ValidationSummaryComponent } from './components/validation-summary/validation-summary.component';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { FoundationRoutes } from './foundation-routing.module';
import { DirtyRecordModule } from './modules/dirty-record/dirty-record.module';
import { DraftRecordModule } from './modules/draft-record/draft-record.module';
import { CacheBusterService } from './services/cache-buster/cache-buster.service';
import { GridDataService } from './services/grid-data/grid-data.service';
import { JsonParseService } from './services/json-parse/json-parse.service';
import { PermissionService } from './services/permission/permission.service';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { NavComponent } from './components/layout/nav/nav.component';
import {DateParseService} from './services/date-parse/date-parse.service';

@NgModule({
  imports: [
    CommonModule,
    CollapseModule,
    FoundationRoutes
  ],
  declarations: [
    NavComponent,
    LoadingComponent,
    ErrorComponent,
    PageTitleComponent,
    ValidationSummaryComponent,
    TickCrossComponent,
    ActionSheetComponent,
    ClickOutsideDirective,
    SidebarComponent
  ],
  providers: [
    // { provide: ErrorHandler, useClass: FoundationErrorHandler },
    GridDataService,
    ActionSheetService,
    JsonParseService,
    PermissionService,
    CacheBusterService,
    DateParseService
  ],
  exports: [
    NavComponent,
    LoadingComponent,
    ErrorComponent,
    PageTitleComponent,
    ValidationSummaryComponent,
    TickCrossComponent,
    ActionSheetComponent,
    ClickOutsideDirective,
    DirtyRecordModule,
    DraftRecordModule,
    SidebarComponent
  ]
})
export class FoundationModule { }

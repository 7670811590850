import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor, FilterDescriptor, State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { serialize } from '../../utils/object.utils';
import { HttpClient } from '@angular/common/http';
import { IKendoGridComponent, KendoGridDataResult } from '../../utils/kendo.utils';
import { GridColumn, GridColumnState } from '../../models/grid.models';
import { JsonParseService } from '../json-parse/json-parse.service';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class GridDataService {
  private readonly stateSuffix: string = '-grid-state';
  private readonly filtersSuffix: string = '-grid-filters';
  private readonly columnsSuffix: string = '-grid-columns';

  constructor(
    private http: HttpClient,
    private jsonParse: JsonParseService) { }

  getGridData<TResult, TFilters>(
    gridName: string, url: string, state: State, filters?: TFilters): Observable<KendoGridDataResult<TResult>> {
    if (state.filter) {
      for (let i = state.filter.filters.length - 1; i >= 0; i--) {
        const filter = state.filter.filters[i] as FilterDescriptor;
        if (filter.operator === 'contains' && filter.value === '')
          state.filter.filters.splice(i, 1);
      }
    }

    this.saveGridState(gridName, state, filters);

    const stateClone = cloneDeep(state);

    this.sanitizeFilterStrings(stateClone.filter);

    const stateQuery = toDataSourceRequestString(stateClone);
    const filterQuery = !filters ? '' : serialize(filters);
    return this.http.get<KendoGridDataResult<TResult>>(`${url}?${filterQuery}&${stateQuery}`);
  }

  saveGridState<TFilters>(gridName: string, state: State, filters?: TFilters) {
    if (state)
      localStorage.setItem(gridName + this.stateSuffix, JSON.stringify(state));

    if (filters)
      localStorage.setItem(gridName + this.filtersSuffix, JSON.stringify(filters));
  }

  applyCachedState(gridName: string, component: IKendoGridComponent) {
    const stateJson = localStorage.getItem(gridName + this.stateSuffix);
    const customFiltersJson = localStorage.getItem(gridName + this.filtersSuffix);
    const columnsJson = localStorage.getItem(gridName + this.columnsSuffix);

    if (stateJson) {
      const stateObj = JSON.parse(stateJson);
      this.jsonParse.parseDates(stateObj);
      component.state = stateObj;
      localStorage.removeItem(gridName + this.stateSuffix);
    }

    if (customFiltersJson) {
      const customFiltersObj = JSON.parse(customFiltersJson);
      this.jsonParse.parseDates(customFiltersObj);
      component.filters = customFiltersObj;
      localStorage.removeItem(gridName + this.filtersSuffix);
    }

    if (columnsJson) {
      const columnsObj = JSON.parse(columnsJson) || [];

      this.jsonParse.parseDates(columnsObj);
      component.gridColumnState.selected = columnsObj;

      this.updateColumnVisibility(component);
      this.updateColumnWidths(component);
    }
  }

  resetGrid<TFilters>(gridName: string, component: IKendoGridComponent) {
    delete component.state.filter;
    component.state.skip = 0;
    component.state.sort = [];
    component.filters = !!component.filters ? {} : null;

    this.applyDefaultFilters(component);
    this.applyDefaultSort(component);
  }

  removeFilter(component: IKendoGridComponent, field: string) {
    const state = component.state;

    if (!state || !state.filter)
      return;

    const composite = state.filter.filters.find((cf: CompositeFilterDescriptor) =>
      !!cf.filters &&
      cf.filters.some((f: FilterDescriptor) => f.field === field)
    ) as CompositeFilterDescriptor;

    if (!!composite) {
      const filters = composite.filters.filter((f: FilterDescriptor) => f.field === field) as FilterDescriptor[];
      if (filters) {
        filters.forEach(f => {
          const i = composite.filters.indexOf(f);
          composite.filters.splice(i, 1);
        });
        if (!composite.filters.length) {
          const i = state.filter.filters.indexOf(composite);
          state.filter.filters.splice(i, 1);
        }
      }
    } else {
      const filter = state.filter.filters.find((f: FilterDescriptor) => f.field === field);
      if (filter) {
        const i = state.filter.filters.indexOf(filter);
        state.filter.filters.splice(i, 1);
      }
    }
  }

  initColumnState(component: IKendoGridComponent): void {
    component.gridColumnState = new GridColumnState();
    component.gridColumnState.selected = [];
    this.applyDefaultColumns(component);
    this.applyDefaultSort(component);
  }

  saveColumnState(gridName: string, component: IKendoGridComponent, preventVisibilityRefresh: boolean = false) {
    if (!component.gridColumnState)
      return;

    if (!preventVisibilityRefresh) {
      this.updateColumnVisibility(component);
      component.updateGrid();
    }

    localStorage.setItem(gridName + this.columnsSuffix, JSON.stringify(component.gridColumnState.selected));
  }

  resetColumnState(gridName: string, component: IKendoGridComponent) {
    localStorage.removeItem(gridName + this.columnsSuffix);
    this.initColumnState(component);
    this.applyDefaultFilters(component);
  }

  private applyDefaultSort(component: IKendoGridComponent) {
    if (
      (component.state && component.state.sort && component.state.sort.length) ||
      (!component.gridColumnState || !component.gridColumnState.options)) {
      return;
    }
    component.gridColumnState.options.forEach((e, i) => {
      if (!!e.defaults && !!e.defaults.sort) {
        const s = e.defaults.sort;
        if (!component.state.sort)
          component.state.sort = [];
        component.state.sort.push({ field: e.field, dir: s.dir });
      }
    });
  }

  private applyDefaultFilters(component: IKendoGridComponent) {
    if (component.gridColumnState != null) {
      component.gridColumnState.options.forEach((option, i) => {
        if (!!option.defaults && !!option.defaults.filter) {
          const defaultFilter = option.defaults.filter;

          if (!component.state.filter)
            component.state.filter = { filters: [], logic: 'and' };

          const existing = component.state.filter.filters.find((f: FilterDescriptor) => f.field === option.field) as FilterDescriptor;

          if (!!existing) {
            existing.operator = defaultFilter.operator;
            existing.value = defaultFilter.value;
          } else {
            component.state.filter.filters.push({ field: option.field, operator: defaultFilter.operator, value: defaultFilter.value });
          }
        }
      });
    }
  }

  private applyDefaultColumns(component: IKendoGridComponent) {
    component.gridColumnState.options.forEach((col, i) => {
      col.ordinal = i;

      this.applyDefaultColumnsWidths(col);

      if (col.isDefault || col.isDefault === undefined) {
        col.isDefault = true;
        component.gridColumnState.selected.push(col);
      }

      component.gridColumnState.state[col.field] = { visible: col.isDefault, width: col.width, minWidth: col.minWidth };
    });
  }

  private updateColumnVisibility(component: IKendoGridComponent) {
    component.gridColumnState.options.forEach(e => {
      const selected = component.gridColumnState.selected.find(s => s.field === e.field);
      const isSelected = !!selected;
      component.gridColumnState.state[e.field].visible = isSelected;

      if (!isSelected)
        this.removeFilter(component, e.field);
    });
  }

  private updateColumnWidths(component: IKendoGridComponent) {
    component.gridColumnState.options.forEach(e => {
      const selected = component.gridColumnState.selected.find(s => s.field === e.field);
      if (!selected)
        return;
      component.gridColumnState.state[e.field].width = selected.width;
    });
  }

  private applyDefaultColumnsWidths(columm: GridColumn) {
    if (!columm.defaults || !columm.defaults.width)
      return;

    columm.minWidth = columm.defaults.minWidth || 50;
    columm.width = columm.defaults.width || columm.minWidth;
  }

  private sanitizeFilterStrings(filter: CompositeFilterDescriptor): void {
    const sanitize = (filters: (CompositeFilterDescriptor | FilterDescriptor)[]) => {
      filters.forEach(f => {
        let descriptor: CompositeFilterDescriptor | FilterDescriptor;
        descriptor = f as CompositeFilterDescriptor;
        if (descriptor.filters) {
          sanitize(descriptor.filters);
        } else {
          descriptor = f as FilterDescriptor;
          if (typeof (descriptor.value) === 'string' && descriptor.value.includes('&'))
            descriptor.value = encodeURIComponent(descriptor.value);
        }
      });
    };

    if (filter && filter.filters) {
      sanitize(filter.filters);
    }
  }
}

import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { CacheBusterService } from './_foundation/services/cache-buster/cache-buster.service';
import '@progress/kendo-angular-intl/locales/en-GB/all';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  environmentLabel: string = environment.name;

  constructor(
    private cacheBuster: CacheBusterService
  ) {
    cacheBuster.init();
  }
}
